export const IframeImage = (props: any) => {
    return (
      <iframe
        src={props.src}
        style={{
          position: 'absolute',
          right: '15%',
          top: '20%',
          width: 800,
          height: 700,
          marginLeft: -10,
          marginTop: -10,
          display: props.dsp,
          zIndex: props.zInd,
        }}
      />
    );
};