import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useAtomValue, useSetAtom } from 'jotai';

import { funnelsAtom, updateFunnelAtom } from '../../../atoms/funnels';
import { ImagePicker } from '@/components/common/ImagePicker';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

const AffBannerImagesForm = ({ formData }: any) => {
  const updateFunnel = useSetAtom(updateFunnelAtom);
  const funnels = useAtomValue(funnelsAtom);
  const [form] = Form.useForm();
  
  useEffect(() => {
    form.setFieldsValue({ ['bannerImageAffid']: funnels.funnel?.details?.bannerImageAffid });
  }, [form]);

  const onAdd = (formValues: any) => {
    if (formValues) {
      updateFunnel(formData.ID, {
        details: {
          ...formValues,
        },
        ID: formData.ID,
      });
    }
  };

  return (
    <>
      <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 20 }}>
        Change Banner Image for Affiliates by their AFFID{' '}
      </h2>
      <Form form={form} layout="vertical" onFinish={onAdd}>
        <Form.List name="bannerImageAffid">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', justifyContent: 'center', marginLeft: 20 }}
                  align="center"
                  size={[16, 16]}
                  wrap
                >
                  <Row gutter={24}>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'affid_img']}
                        rules={[{ required: true, message: 'Missing affiliate ID' }]}
                        label="Affiliate ID"
                      >
                        <Input maxLength={4} placeholder="Affiliate ID" />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...restField}
                        name={[name, 'desktop_src']}
                        label="Desktop Image needs to be 2978 x 194 px  "
                      >
                        <ImagePicker funnelId={formData.ID} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...restField}
                        name={[name, 'mobile_src']}
                        label="Mobile Image needs to be 422 x 97 px  "
                      >
                        <ImagePicker funnelId={formData.ID} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red' }} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ color: 'green' }}>
                  Add images for other affiliates
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '10%', justifyContent: 'center', left: '45%', marginTop: 10 }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AffBannerImagesForm;
