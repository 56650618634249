import { PictureFilled } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useState } from 'react';

import { ImageGallery } from './ImageGallery';

export const ImagePicker = ({
  funnelId,
  value = '',
  onChange,
  onFinish,
}: {
  funnelId: number | undefined;
  value?: string;
  onChange?: (value: string) => void;
  onFinish?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);

  const onOk = () => {
    onChange?.(selected);
    onFinish?.();
    setOpen(false);
  };

  const onSelected = (image: string) => {
    setSelected(image);
  };

  return (
    <>
      <Space.Compact style={{ display: 'flex' }}>
        <Input
          placeholder="Select image"
          value={value}
          onChange={(e) => onChange?.(e.target.value || '')}
          onPressEnter={() => onFinish?.()}
        />
        <Button type="primary" onClick={() => setOpen(true)} icon={<PictureFilled />} key="add"></Button>
      </Space.Compact>
      <Modal open={open} title="Select image from gallery" onOk={onOk} onCancel={() => setOpen(false)}>
        <ImageGallery funnelId={funnelId} value={value} onSelected={onSelected} />
      </Modal>
    </>
  );
};
